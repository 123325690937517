import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { jwtCredentials } from '../signals';

export const authGuard: CanActivateFn = (_route, _state) => {
  const router = inject(Router);
  if (jwtCredentials()?.accessToken) {
    return true;
  } else {
    return router.createUrlTree(['/login'], { queryParams: { returnUrl: _state.url } });
  }
};
